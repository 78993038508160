.resume-container {
  background: #473e94;
  width: 98%;
  margin: 80px auto 50px;
  font-family: "Inconsolata", monospace;
}

.dot {
  background: #3ecf8e;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  position: relative;
  top: -17px;
  left: 22px;
  margin-right: 0.5rem;
}

.resume-container .resume-inside {
  padding: 16px 20px 16px;
}

.resume-container h3 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
}

.resume-container h4 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  color: #aaa;
  margin: 50px 0 20px;
}

.resume-container ul {
  margin: 0;
  list-style: none;
}

.resume-container li {
  margin-bottom: 15px;
  color: #fff;
}

.resume-container h5 {
  font-family: "Inconsolata", monospace;
  font-weight: 400;
  color: #aaa;
  margin: 40px 0 6px;
}

.resume-container .time {
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  color: #aaa;
}

.resume-container:before {
  border-radius: 6px 6px 0 0;
  content: "";
  background: #4f40ad;
  width: 100%;
  display: block;
  height: 20px;
  position: relative;
  top: -4px;
}

.resume-container:after {
  border-radius: 0 0 6px 6px;
  content: "";
  background: #4f40ad;
  width: 100%;
  display: block;
  height: 20px;
  position: relative;
  bottom: -4px;
}

#technologies {
  margin: 6rem 0 6rem 0;
  color: #473e94;
}

.dots {
  display: flex;
}
