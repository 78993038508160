footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #473e94;
  padding-top: 3rem;
  /* push the footer to the bottom */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

footer .navigation {
  height: 4rem;
}

.footer-links {
  display: flex;
  width: 50%;
  justify-content: space-around;
  padding: 3rem;
}

.footer-links li {
  margin: 0 0.5rem;
}

.footer-links a {
  font-size: 1.6rem;
}

footer a {
  font-size: 1rem;
}

#twitter {
  background-color: #00aced;
}

#linkedin {
  background-color: #0077b5;
}

@media only screen and (max-width: 1160px) {
  footer .moveUp:hover {
    transform: none;
  }
}

@media only screen and (max-width: 480px) {
  footer .navigation a {
    padding: 0;
    font-size: 10px;
  }
  footer .copyrights {
    font-size: 0.8rem;
    margin-right: 0.8rem;
  }
}
