.project-title {
  font-size: 3rem;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #fff;
}

.projects-list li {
  margin-top: 5rem;
}
