html {
  color: white;
  font-size: 12px;
  font-family: "Lato", "Alegreya Sans SC", sans-serif;
  /* push the footer to the bottom */
  position: relative;
  min-height: 100%;
}

/* push the footer to the bottom */

#root {
  margin-bottom: 20rem;
}

body {
  background: linear-gradient(150deg, #53f 15%, #05d5ff 70%, #a6ffcb 94%);
}

h1 {
  font-size: 2.3rem;
  margin-bottom: 1.45rem;
}

h2 {
  font-size: 2.1rem;
  margin-bottom: 1.45rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.7rem;
}

h5 {
  font-size: 1.6rem;
}

p {
  margin-bottom: 1.45rem;
  font-size: 1.8rem;
  line-height: 1.3;
}

a {
  text-decoration: none;
  color: white;
}

li {
  font-size: 1.6rem;
}

.strong {
  font-weight: bold;
  font-size: 110%;
}

.moveUp:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.link-style {
  color: #22e742;
  font-weight: bold;
}

.link-style:hover {
  color: #473e94;
}

.link-style-2 {
  color: #22e742;
}

.link-style-2:hover {
  color: #1db1ff;
}

.link-style-3 {
  color: #0069ff;
  font-weight: bold;
}

.link-style-3:hover {
  color: #22e742;
}

.link-style-4 {
  color: #fff;
}

.link-style-4:hover {
  color: #4f40ad;
}

.link-style-5 {
  color: #4f40ad;
}

.main-container {
  max-width: 90rem;
  margin: auto;
  padding: 15rem 10rem 4rem 10rem;
  display: flex;
  flex-direction: column;
}

.em {
  font-style: italic;
  font-weight: bolder;
  letter-spacing: 1px;
}

.page-heading {
  font-size: 3rem;
  margin-bottom: 4rem;
  text-transform: uppercase;
  font-weight: 900;
  -webkit-letter-spacing: 5px;
  -moz-letter-spacing: 5px;
  -ms-letter-spacing: 5px;
  letter-spacing: 5px;
  text-align: center;
}

.bottom-links {
  display: flex;
  background-color: #4f40ad;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.copyrights {
  margin-right: 2rem;
}

.button {
  background: #3ecf8e;
  text-shadow: 0 1px 3px rgba(36, 180, 126, 0.4);
  line-height: 4rem;
  padding: 0.9rem 1.4rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  transition: all 0.15s ease;
}

@media only screen and (max-width: 1160px) {
  main .main-container {
    padding: 15rem 4rem 3rem 4rem;
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 10px;
  }
  main .main-container {
    padding: 12rem 3rem 3rem;
  }
}
