.navigation {
  display: flex;
  align-items: center;
  background-color: #4f40ad;
  border-radius: 0 0 0 3px;
}

.navigation li {
  display: inline-block;
  padding: 0 3rem;
}

.navigation ul {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navigation a {
  padding: 0.65rem 3rem;
  transition: all 0.1s ease;
}

.underline:hover {
  border-bottom: 4px solid #3ecf8e;
}

@media only screen and (max-width: 1160px) {
  .bottom-links .navigation a {
    padding: 0;
  }
}

@media only screen and (max-width: 480px) {
  .underline:hover {
    border-bottom: none;
  }
  .bottom-links .navigation a {
    padding: 0;
  }
  .bottom-links .navigation li {
    padding: 0 1rem;
  }
}
