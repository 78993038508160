/* Header.css */

header {
  position: fixed;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  font-weight: 600;
  height: 4.5rem;
}

.initials {
  /* display: inline-block; */
  line-height: 4.5rem;
}

.logo {
  padding: 0 1rem;
  text-align: center;
  background-color: #3ecf8e;
  display: inline-block;
  z-index: 10;
  transition-duration: 0.5s;
  border-radius: 3px;
}

.logo-container {
  z-index: 10;
}

header:hover .name {
  left: 2rem;
}

header:hover .logo {
  transform: rotate(360deg);
}

.name {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  display: inline-block;
  position: relative;
  left: -20rem;
  transition: left 0.5s cubic-bezier(0, 1.65, 0.85, 0.92);
}

@media only screen and (max-width: 1160px) {
  header .navigation {
    flex-grow: 1;
    border-radius: 0;
  }
  .logo-container .logo {
    border-radius: 0;
  }
  .logo-container .name {
    display: none;
  }
  header .navigation li {
    padding: 0;
  }
}

@media only screen and (max-width: 480px) {
  header .navigation a {
    padding: 0 0.3rem;
    font-size: 80%;
  }
  body header:hover .logo {
    transform: none;
  }
}
